import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from "@/router";

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useReportsList(data) {
  if(!data){
    data = {};
  }
  // Use toast
  const toast = useToast()

  const refReportListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'reportStatus', sortable: true },
    { key: 'template', sortable: true },
    { key: 'user' },
    { key: 'organization' },
    { key: 'reportMessage', thClass: "report-message-th-class", tdClass:"report-message-class" },
    { key: 'issuedDate', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalReports = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(data.status || null)
  const organizationFilter = ref(data.organizations || null)

  const dataMeta = computed(() => {
    const localItemsCount = refReportListTable.value ? refReportListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalReports.value,
    }
  })

  const refetchData = () => {
    refReportListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, organizationFilter], () => {
    refetchData()
  })

  const fetchReports = (ctx, callback) => {
    if(!organizationFilter.value){
      return callback([])
    }
    const query = {
      q: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      status: statusFilter.value,
      organizations: organizationFilter.value
    };

    router.replace({
      params: router.currentRoute.params,
      query: { ...router.currentRoute.query, ...query },
    });
    store
      .dispatch('app-report/fetchReports', query)
      .then(response => {
        debugger;
        callback(response.data.results)
        totalReports.value = response.data.totalResults
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching reports' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveReportStatusVariantAndIcon = status => {
    if (status === 'IN_PROGRESS') return { variant: 'warning', icon: 'PieChartIcon' }
    if (status === 'CLOSED') return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'OPEN') return { variant: 'danger', icon: 'InfoIcon' }
    if (status === 'MODIFIED_OUTPUT') return { variant: 'dark', icon: 'ShuffleIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const resolveClientAvatarVariant = status => {
    if (status === 'OPEN') return 'danger'
    if (status === 'IN_PROGRESS') return 'warning'
    if (status === 'CLOSED') return 'success'
    if (status === 'MODIFIED_OUTPUT') return 'dark'
    return 'primary'
  }

  return {
    fetchReports,
    tableColumns,
    perPage,
    currentPage,
    totalReports,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refReportListTable,

    statusFilter,
    organizationFilter,
    resolveReportStatusVariantAndIcon,
    resolveClientAvatarVariant,

    refetchData,
  }
}
